/** @format */

import React, { useState, useEffect } from "react";
import LotStat from "../LotStat/LotStat";
import Papa from "papaparse";
import Cookies from "js-cookie";
import { getInstance } from "../../../../API/DocQuery";
import DatePicker from "react-datepicker";
import { updateInstanceData } from "../../../../API/updateInstance";
import NavBar from "../../../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { setFilters, setFilterField } from "../../../../Redux/filtersSlice";
import {
  faSortUp,
  faSortDown,
  faFileCsv,
  faSearch,
  faCopy,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { getModel } from "../../../../API/modelList";
import "react-datepicker/dist/react-datepicker.css";
import { getDoc } from "../../../../API/DocList";

const Ocr01Lots = () => {
  const savedFilters = JSON.parse(localStorage.getItem("filters")) || {
    sInstanceName: "",
    sStatus: "",
    sFinalCustomer: "",
    dCreationDate: null,
    iCreatedDocs: null,
    iExpectedDocs: null,
    iUploadedDocs: null,
    iCompletedDocs: null
  };
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [filter, setFilter] = useState(savedFilters);
  const [sortField, setSortField] = useState("dCreationDate");
  const [sortOrder, setSortOrder] = useState("desc"); // 'asc' ou 'desc'
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [modelInstanceStatuses, setModelInstanceStatuses] = useState([]);
  const [ModelName, setModelName] = useState([]);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: null,
    toDate: null,
    filterType: "between",
  });
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const DatePickerStyle = {
    width: '400px',
    height: '200px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    background: 'white',
    border: '10px solid #183152',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const handleCopy = (DocId) => {
    toast.success(`ID copié: ${DocId}`, {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    // Sauvegarder les filtres dans le localStorage
    localStorage.setItem("filters", JSON.stringify(filter));
  }, [filter]);

  const formatCustomDate = (dateString) => {
    const date = parseCustomDate(dateString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return (
      date.toLocaleDateString("fr-FR", options) +
      " " +
      date.toLocaleTimeString("fr-FR")
    );
  };

  useEffect(() => {
    const idToken = Cookies.get("Token"); // Récupérer le token depuis les cookies
    const model = Cookies.get("modelId");
    const loadAndProcessData = async () => {
      try {
        const responseData = await getInstance(idToken, model);
        const loadModelData = async () => {
          const modelData = await getModel(idToken); // Supposons que ceci retourne directement le tableau
          setModelName(modelData[0].ModelName);
          if (modelData && modelData.length > 0) {
            const instanceStatuses = modelData[0].ModelInstanceStatus;
            console.log("Statuts chargés: ", instanceStatuses);
            setModelInstanceStatuses(instanceStatuses);
          }
        };
        loadModelData();
        if (Array.isArray(responseData)) {
          setData(responseData);
          const loadedStatuses = responseData.map((item) => item.sStatus);
          setUniqueStatuses([...new Set(loadedStatuses)]);
        } else {
          console.error(
            "Les données chargées ne sont pas un tableau",
            responseData
          );
          setData([]);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données", error);
      }
    };

    loadAndProcessData();
  }, []);

  //tri par defaut par date de creation decroissante
  // Fonction pour trier les données
  const sortData = (data, field, order) => {
    return data.sort((a, b) => {
      const dateA = parseCustomDate(a[field]);
      const dateB = parseCustomDate(b[field]);
      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
  };

  // Utiliser useEffect pour trier les données une fois que le composant est monté
  useEffect(() => {
    setData((prevData) => sortData([...prevData], sortField, sortOrder));
  }, [sortField, sortOrder]);

  const handleSubmitChange = async (kInstanceId) => {
    const updatedItem = data.find((item) => item.kInstanceId === kInstanceId);
    if (updatedItem) {
      const { sStatus } = updatedItem; // Extraction des valeurs à envoyer
      console.log(sStatus, kInstanceId);
      const idToken = Cookies.get("Token"); // Extraction du token

      try {
        const response = await updateInstanceData(
          idToken,
          kInstanceId,
          sStatus
        );
        console.log("Réponse de la mise à jour: ", response);
        //alert ok
        //toast.success("Instance mise à jour avec succès!");
      } catch (error) {
        toast.error("Erreur lors de la mise à jour de l'instance");
        console.error("Erreur lors de la mise à jour des données", error);
      }
    }
  };
  const formatDateForStorage = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}${month}${day}-${hours}${minutes}${seconds}`;
  };
  useEffect(() => {
    // Charger les filtres sauvegardés depuis le localStorage
    const savedFilters = JSON.parse(localStorage.getItem("filters"));
    if (savedFilters) {
      setFilter(savedFilters);
    }
  }, []);

  useEffect(() => {
    // Sauvegarder les filtres dans le localStorage
    localStorage.setItem("filters", JSON.stringify(filter));
  }, [filter]);

  const applyFilters = (data) => {
    return data
      .filter((item) =>
        filter.sInstanceName
          ? item.sInstanceName &&
            item.sInstanceName
              .toLowerCase()
              .includes(filter.sInstanceName.toLowerCase())
          : true
      )
      .filter((item) =>
        filter.sStatus ? item.sStatus === filter.sStatus : true
      )
      .filter((item) =>
        filter.sFinalCustomer
          ? item.sFinalCustomer &&
            item.sFinalCustomer
              .toLowerCase()
              .includes(filter.sFinalCustomer.toLowerCase())
          : true
      )
      .filter((item) => {
        if (!dateRangeFilter.fromDate && !dateRangeFilter.toDate) return true;

        const creationDate = parseCustomDate(item.dCreationDate);

        if (dateRangeFilter.fromDate && dateRangeFilter.toDate) {
          return (
            (creationDate >= dateRangeFilter.fromDate &&
              creationDate <= dateRangeFilter.toDate) 
          );
        }

        return true;
      });
  };
  const handleSaveFilters = () => {
    localStorage.setItem("filters", JSON.stringify(filter));
  };
  const parseCustomDate = (dateStr) => {
    if (!dateStr || typeof dateStr !== "string" || dateStr.length !== 15) {
      console.error("Invalid date string:", dateStr);
      return new Date(); // Retourne une date par défaut en cas d'erreur
    }

    const year = parseInt(dateStr.slice(0, 4), 10);
    const month = parseInt(dateStr.slice(4, 6), 10) - 1; // Les mois commencent à 0 en JavaScript
    const day = parseInt(dateStr.slice(6, 8), 10);
    const hours = parseInt(dateStr.slice(9, 11), 10);
    const minutes = parseInt(dateStr.slice(11, 13), 10);
    const seconds = parseInt(dateStr.slice(13, 15), 10);

    return new Date(year, month, day, hours, minutes, seconds);
  };

  const handleInputChange = (event, kInstanceId, key) => {
    const newData = data.map((item) => {
      if (item.kInstanceId === kInstanceId) {
        return {
          ...item,
          [key]:
            event.target.value
        };
      }
      return item;
    });
    setData(newData);
  };

  const applySort = (filteredData) => {
    if (!sortField) return filteredData;
    return filteredData.sort((a, b) => {
      const extractDate = (dateStr) => dateStr.split("-")[0];
      if (["dCreationDate"].includes(sortField)) {
        const dateA = extractDate(a[sortField]);
        const dateB = extractDate(b[sortField]);
        // Comparaison des chaînes de date YYYYMMDD
        return sortOrder === "asc"
          ? dateA.localeCompare(dateB)
          : dateB.localeCompare(dateA);
      }
      // Gestion des nombres
      else if (["iCreatedDocs","iExpectedDocs","iUploadedDocs","iCompletedDocs"].includes(sortField)) {
        const numA = a[sortField] ? parseInt(a[sortField], 10) : 0;
        const numB = b[sortField] ? parseInt(b[sortField], 10) : 0;
        return sortOrder === "asc" ? numA - numB : numB - numA;
      }  else {
        // Gestion des autres champs (chaînes de caractères)
        const strA = a[sortField] ? a[sortField].toLowerCase() : "";
        const strB = b[sortField] ? b[sortField].toLowerCase() : "";
        return sortOrder === "asc"
          ? strA.localeCompare(strB)
          : strB.localeCompare(strA);
      }
    });
  };

  const handleDownloadCSV = () => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "instances.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadCSVDoc = async (instanceId) => {
    try {
      const idToken = Cookies.get("Token"); // Extraction du token

      // Appel à l'API pour obtenir les données
      const data = await getDoc(idToken, instanceId);

      // Conversion des données en CSV
      const csvData = Papa.unparse(data);

      // Création du blob et de l'URL pour le téléchargement
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Création du lien et déclenchement du téléchargement
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "allDocs.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier CSV :", error);
    }
  };
  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", options);
  };

  const updateFilterType = (filterType) => {
    setDateRangeFilter((prevFilter) => ({
      ...prevFilter,
      filterType: filterType,
    }));
  };

  return (
    <>
      <NavBar />
      <div className='page-container' style={{ backgroundColor: "#F7F9F9" }}>
        <h6 style={{ textAlign: "left", marginTop: "10px" }}>
          <Link to='/User/'>All models</Link>
          {" > "}
          {ModelName} {" > "}Instances
        </h6>
        
        
          <LotStat lots={applySort(applyFilters(data))} />
          
          {isVisible && (
          <div style={DatePickerStyle}>
            <table style={{width:'300px'}}>
              <tr>
                <td style={{width:'100px',textAlign:'left'}}>            
                  <label>Date de début :</label>
                </td>
                <td style={{width:'100px'}}>            
                  <DatePicker
                    selected={dateRangeFilter.fromDate}
                    onChange={(date) =>
                      setDateRangeFilter({ ...dateRangeFilter, fromDate: date })
                    }
                    dateFormat='yyyy-MM-dd'
                    className='form-control'
                  />
                </td>
              </tr>
              <tr>
              <td style={{width:'100px',textAlign:'left'}}>            
                  <label>Date de fin :</label>
                </td>
                <td style={{width:'100px'}}>            
                  <DatePicker
                    selected={dateRangeFilter.toDate}
                    onChange={(date) =>
                      setDateRangeFilter({ ...dateRangeFilter, toDate: date })
                    }
                    dateFormat='yyyy-MM-dd'
                    className='form-control'
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2" style={{height:'50px',alignItems:'center', justifyContent:'center'}}><center><button onClick={toggleVisibility}>Fermer</button></center></td>                
              </tr>
            </table>            
          </div>
          )}


        <div
          className='table-responsive'
          style={{
            padding: "20px",
            borderRadius: "5px",
            overflowX: "auto",
            lineHeight: "0.7",
          }}
        >
          <table className='table table-striped'>
            <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <th style={{ backgroundColor: "#183152", width: "100px" }}>
                  Actions
                </th>
                <th
                  style={{ backgroundColor: "#183152", width: "300px" }}
                  colSpan='2'
                >
                  {" "}
                  <input
                    type='text'
                    name='name'
                    placeholder="Nom de l'instance"
                    className='form-control me-2 filter-input' // Ajout de la classe filter-input
                    value={filter.sInstanceName}
                    onChange={(e) =>
                      setFilter({ ...filter, sInstanceName: e.target.value })
                    }
                    style={{ width: "300px" }}
                  />
                </th>
                <th
                  style={{ backgroundColor: "#183152", width: "100px" }}
                  onClick={() => toggleSort("dCreationDate")}
                >
                  <label onClick={toggleVisibility}>Création</label>
                  <br/><br/>
                  {sortField === "dCreationDate" && sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </th>
                <th style={{ backgroundColor: "#183152" }}>
                  <select
                    className='form-select filter-select'
                    onChange={(e) =>
                      setFilter({ ...filter, sStatus: e.target.value })
                    }
                    style={{ width: "100px", textAlign: "left" }}
                  >
                    <option value=''>Statuts</option>
                    {uniqueStatuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </th>
                <th style={{ backgroundColor: "#183152", width: "150px" }}>
                  <input
                    type='text'
                    placeholder='Client final'
                    className='form-control me-2 filter-input'
                    onChange={(e) =>
                      setFilter({ ...filter, sFinalCustomer: e.target.value })
                    }
                    style={{ width: "150px" }}
                  />
                </th>
                <th
                  style={{ backgroundColor: "#183152" }}
                  onClick={() => toggleSort("iExpectedDocs")}
                >
                  Docs attendus
                  <br/><br/>
                  {sortField === "iExpectedDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </th>
                <th
                  style={{ backgroundColor: "#183152" }}
                  onClick={() => toggleSort("iCreatedDocs")}
                >
                  Docs créés
                  <br/><br/>
                  {sortField === "iCreatedDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </th>
                <th
                  style={{ backgroundColor: "#183152" }}
                  onClick={() => toggleSort("iUploadedDocs")}
                >
                  Docs uploadés
                  <br/><br/>
                  {sortField === "iUploadedDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </th>
                <th
                  style={{ backgroundColor: "#183152" }}
                  onClick={() => toggleSort("iCompletedDocs")}
                >
                  Docs terminés
                  <br/><br/>
                  {sortField === "iCompletedDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {applySort(applyFilters(data))
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item) => (
                  <tr
                    key={item.kInstanceId}
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => handleDownloadCSVDoc(item.kInstanceId)}
                      >
                        <FontAwesomeIcon icon={faFileCsv} />
                      </button>
                      <button
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => {
                          // Définir un cookie
                          Cookies.set("instanceId", item.kInstanceId); // Ajout d'une expiration (facultatif)
                          Cookies.set("instanceName", item.sInstanceName);
                          navigate(`/User/Ocr01Docs`);
                        }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle", width:"300px" }}>
                      <span
                        style={{
                          //fontSize: "1.2em",
                          marginLeft: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {item.sInstanceName}
                      </span>
                    </td>
                        <td>
                        <CopyToClipboard
                        text={item.kInstanceId}
                        onCopy={() => handleCopy(item.kInstanceId)}
                      >
                        <button className='btn btn-outline-primary btn-sm'>
                          <FontAwesomeIcon icon={faCopy} />
                        </button>
                      </CopyToClipboard>
                        </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle", lineHeight:'1.5' }}
                    >
                      {formatCustomDate(item.dCreationDate)}
                    </td>
        
                    <td style={{ textAlign: "center", verticalAlign: "middle", width: "100px" }}>                      
                      {item.sStatus}                      
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <span
                        style={{
                          fontSize: "1.2em",
                          marginLeft: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {item.sFinalCustomer}
                      </span>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {item.iExpectedDocs}
                    </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {item.iCreatedDocs}
                    </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {item.iUploadedDocs}
                    </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {item.iCompletedDocs}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div>
            <button
              className='btn btn-outline-primary btn-sm'
              onClick={handleDownloadCSV}
            >
              <FontAwesomeIcon icon={faFileCsv} /> Télécharger CSV
            </button>
          </div>
          <div>
            {Array.from(
              { length: Math.ceil(data.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  className='btn btn-outline-primary btn-sm me-2'
                  onClick={() => setCurrentPage(index + 1)}
                  style={{
                    fontWeight: currentPage === index + 1 ? "bold" : "normal",
                  }}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
          <div className='col-md-3'>
            <button className='btn btn-primary' onClick={handleSaveFilters}>
              Sauvegarder les filtres
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ocr01Lots;
