import process from "process";
const API_BASE_URL =process.env.REACT_APP_API_URL; // Remplacez par l'URL de base de votre API
let docViewWindow = null;

export async function presignedURL(token, docId, modelId, instanceId) {
  // Validation des paramètres pour s'assurer qu'ils ne sont pas undefined
  if (!token || !docId || !modelId || !instanceId) {
    console.error('Un ou plusieurs paramètres requis sont undefined', { token, docId, modelId, instanceId });
    throw new Error('Un ou plusieurs paramètres requis sont undefined');
  }

  const apiUrl = `${API_BASE_URL}/document/view`;
  console.log(instanceId, "view");
  console.log('modelid=',modelId);

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type":"application/json"
      },
      body: JSON.stringify({
        "InstanceId": instanceId,
        "ModelId": modelId,
        "Duration": 600,
        "DocumentId": docId
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);

    const presignedUrl = data.message.PresignedURL;
  

    return presignedUrl;
  } catch (error) {
    throw new Error(error.message);
  }
}
