/** @format */

import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";

const DocStat = ({ lots }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Calcul des statistiques de statuts pour le Doughnut chart
  const statusCounts = lots.reduce((acc, lot) => {
    const status = lot.Status;
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});
  const options = {
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true, // Permettre l'utilisation de points à la place des carrés dans la légende
          textAlign: 'start', // Aligner le texte de début
          font: {
            size: 14,
          },
          padding: 15,
        },
      },
    },
  };
  

  const backgroundColors = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
  ];

  const borderColors = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ];

  const chartData = {
    labels: Object.keys(statusCounts),
    datasets: [
      {
        label: "Répartition des statuts",
        data: Object.values(statusCounts),
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  // Calcul des statistiques des documents, des plans et de leur complexité
  const stats = lots.reduce(
    (acc, lot) => {
      acc.totalDocs++;
      switch (lot.Status) {        
        case "extracting":
          acc.totalExtracting++;
          break;
        case "waiting_review":
            acc.totalReview++;
            break;
        case "completed":
            acc.totalCompleted++;
            break;        
        default:
          break;
      }            
      return acc;
    },
    {      
      totalDocs: 0,      
      totalExtracting: 0,
      totalReview: 0,
      totalCompleted: 0      
    }
  );

  return (
    <div className='container mt-4 collapsible-section' style={{width:'600px'}}>
      <div className='titre-stat' onClick={toggleCollapse}>
        <h2>Statistiques</h2>
        <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
      </div>
      {!isCollapsed && (
        <div className='row'>
          <div className='col-md-6'>
            <div
              className='chart-container'
              style={{ width: "100%", height: "300px" }}
            >
              <Doughnut data={chartData} options={options} />
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className='table-responsive'
              style={{
                height: "200px",
                lineHeight: "0.6",
                borderRadius: "10px",
              }}
            >
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>Catégories</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Documents</td>
                    <td>{stats.totalDocs}</td>
                  </tr>
                  <tr>
                    <td>En cours</td>
                    <td>{stats.totalExtracting}</td>
                  </tr>
                  <tr>
                    <td>Vidéocodage</td>
                    <td>{stats.totalReview}</td>
                  </tr>
                  <tr>
                    <td>Terminés</td>
                    <td>{stats.totalCompleted}</td>
                  </tr>              
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocStat;
