/** @format */

import React, { useState, useEffect } from "react";

import Cookies from "js-cookie";
import { getDoc } from "../../../../API/DocList";

import NavBar from "../../../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./TabDoc.css";
import { docView } from "../../../../API/DocView";
import {
  faSortUp,
  faSortDown,
  faLink,
  faCopy,
  faFileAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { getModel } from "../../../../API/modelList";
//import { getAsanaUserList } from "../../../../API/asanaUserList"; // In cae we need it that's a good start...
import "react-datepicker/dist/react-datepicker.css";
import { docUpdate } from "../../../../API/docUpdate";
import DocStat from "../LotStat/DocStats";
import Modal from "react-modal";
import { attachZip } from "../../../../API/attachZip";
import { docAttachment } from "../../../../API/docAttachment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import config from "./config.json"; // Charger la configuration

const DefaultDoc = () => {
  const [modelInstanceStatuses, setModelInstanceStatuses] = useState([]);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileExtension, setFileExtension] = useState(""); //pr recuperer l'extension des pieces jointes
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState({
    FinalCustomer: "",
    Status: "",
    isvalid: null
  });

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [ModelName, setModelName] = useState([]);
  const [hover, setHover] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [modelId, setModelId] = useState(); // Model ID [TODO: Get from API
  const [itemsPerPage, setItemPerPage] = useState(10);
  const navigate = useNavigate();
  const instanceId = Cookies.get("instanceId");
  const instanceName = Cookies.get("instanceName");
  const [configData, setConfigData] = useState({});
  const itemNames = configData.itemNames || [];
  const itemVars = configData.itemVars || [];

  useEffect(() => {
    const idToken = Cookies.get("Token");
    const loadModelData = async () => {
      const modelData = await getModel(idToken);
      setModelName(modelData[0].ModelName);
      setModelId(modelData[0].ModelId);
      const instanceStatuses = modelData[0].ModelDocumentStatus;
      console.log("Statuts chargés: ", instanceStatuses);
      setModelInstanceStatuses(instanceStatuses);
    };

    console.log("modelIdd", modelId);
    const loadAndProcessData = async () => {
      try {
        const responseData = await getDoc(idToken, instanceId);
        console.log("Données chargées: ", responseData);
        loadModelData();
        if (Array.isArray(responseData)) {
          setData(responseData);
          const loadedStatuses = responseData.map((item) => item.Status);
          setUniqueStatuses([...new Set(loadedStatuses)]);
        } else {
          console.error(
            "Les données chargées ne sont pas un tableau",
            responseData
          );
          setData([]);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données", error);
      }
    };

    loadAndProcessData();
  }, []);

  useEffect(() => {
    setConfigData(config);
  }, []);

  const handleCheckboxChange = (event, DocId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedDocuments([...selectedDocuments, DocId]);
    } else {
      setSelectedDocuments(selectedDocuments.filter((id) => id !== DocId));
    }
  };

  const handleUpdateSelected = async () => {
    var errcount = 0;
    for (const DocId of selectedDocuments) {
      try {
        await handleSubmitChange(DocId);
      } catch (error) {
        errcount++;
      }
    }

    // Check if there were some errors ?
    if (errcount != 0) {
      toast.error(
        `${errcount} erreur(s) lors de la mise à jour des documents !`,
        {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      // Notifier l'utilisateur que la mise à jour est réussie
      toast.success("Données mises à jour avec succès !", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // Réinitialiser les documents sélectionnés après la mise à jour
      setSelectedDocuments([]);
      // Reload page
      window.location.reload();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const fileName = file.name;
      const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1);
      setFileExtension(fileExt);
      console.log("File extension is", fileExt);
    }
  };
  const handleItemsPerPageChange = (event) => {
    setItemPerPage(Number(event.target.value));
  };
  const handleUpload = async () => {
    if (!selectedFile) {
      // Vérifier si un fichier est sélectionné
      //alert("Veuillez sélectionner un fichier à télécharger.");
      const notifyFileSelection = () => {
        toast.warn("Veuillez sélectionner un fichier.", {
          autoClose: false, // Le toast ne se ferme pas automatiquement
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };
      notifyFileSelection();
      return;
    }
    const idToken = Cookies.get("Token");

    try {
      // Appeler la fonction attachZip avec les paramètres nécessaires
      await attachZip(
        instanceId, // ID de l'instance
        selectedDocumentId, // ID du document
        modelId, // ID du modèle
        selectedFile, // Fichier sélectionné
        idToken, // Jeton d'authentification
        fileExtension
      );

      toast.success("Fichier chargé avec succès !");
      window.location.reload();
      closeModal();
    } catch (error) {
      // Gérer les erreurs lors du téléchargement du fichier
      console.error("Erreur lors du téléchargement du fichier :", error);
      //alert("Erreur lors du téléchargement du fichier : " + error.message);//c'est pas un upload??
      const notifyFileErrorUpload = (errorMessage) => {
        toast.error(`Erreur lors du chargement du fichier : ${errorMessage}`, {
          autoClose: false, // Le toast ne se ferme pas automatiquement
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };
      notifyFileErrorUpload(error.message);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCopy = (DocId) => {
    toast.success(`ID copié: ${DocId}`, {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleInputChange = (event, DocId, key) => {
    const { value, type, checked } = event.target;

    if (type === "checkbox") {
      if (checked) {
        setSelectedDocuments((prevSelected) => [...prevSelected, DocId]);
      } else {
        setSelectedDocuments((prevSelected) =>
          prevSelected.filter((id) => id !== DocId)
        );
      }
    } else {
      const newValue = key === "isvalid" ? event.target.value === "true" : value;
      const newData = data.map((item) => {
        if (item.DocId === DocId) {
          const updatedItem = { ...item, [key]: newValue };
          const isAnyFieldModified = Object.keys(updatedItem).some(
            (field) => field !== "DocId" && item[field] !== updatedItem[field]
          );
          // Update the checkbox based on the modification
          if (isAnyFieldModified) {
            // If at least one modifiable field is modified, check the checkbox
            setSelectedDocuments((prevSelected) => [...prevSelected, DocId]);
          } else {
            // If no modifiable field is modified, remove the checkbox
            setSelectedDocuments((prevSelected) =>
              prevSelected.filter((id) => id !== DocId)
            );
          }
          return updatedItem;
        }
        return item;
      });
      // Update the state with the modified data
      setData(newData);
    }
  };

  const handleSubmitChange = async (DocId) => {
    const updatedItem = data.find((item) => item.DocId === DocId);
    if (updatedItem) {
      const { Complexite, Priorite, isvalid } = updatedItem;
      const idToken = Cookies.get("Token");
      try {
        const response = await docUpdate(
          idToken,
          instanceId,
          DocId,
          Complexite,
          Priorite,
          isvalid,
          //"qualified"
        );
        console.log("Mise à jour réussie", response);
        window.location.reload();
      } catch (error) {
        console.error("Erreur lors de la mise à jour des données", error);
      }
    }
  };

  const applyFilters = (data) => {
    return data
      .filter((item) =>
        filter.FileName
          ? item.FileName.toLowerCase().includes(filter.FileName.toLowerCase())
          : true
      )
      .filter((item) =>
        filter.FinalCustomer
          ? item.FinalCustomer.toLowerCase().includes(
            filter.FinalCustomer.toLowerCase()
          )
          : true
      )
      .filter((item) =>
        filter.Status
          ? item.Status.toLowerCase().includes(filter.Status.toLowerCase())
          : true
      )
      .filter((item) =>
        filter.isvalid
          ? item.isvalid === (filter.isvalid === "true" ? true : false)
          : true
      );
  };

  const applySort = (filteredData) => {
    if (!sortField) return filteredData;
    return filteredData.sort((a, b) => {
      if (sortField === "LastUpdate") {
        const dateA = new Date(a[sortField]);
        const dateB = new Date(b[sortField]);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else if (["Batiment", "Etage", "TypePlan"].includes(sortField)) {
        const valueA = a[sortField] ? a[sortField].toString() : "";
        const valueB = b[sortField] ? b[sortField].toString() : "";
        return sortOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return 0;
      }
    });
  };

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const callGetModel = (docId, modelId) => {
    const token = Cookies.get("Token");
    const instanceId = Cookies.get("instanceId");
    docView(token, docId, modelId, instanceId);
  };

  const formatDate = (dateStr) => {
    if (!dateStr || dateStr.length < 8) return "";
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    return `${day}-${month}-${year}`;
  };

  const handleDownload = async (docId, fileType) => {
    try {
      const token = Cookies.get("Token");
      await docAttachment(token, instanceId, docId, fileType, modelId);

      console.log("Téléchargement réussi !");
    } catch (error) {
      console.error("Erreur lors du téléchargement :", error);
    }
  };
  const pageCount = Math.ceil(data.length / itemsPerPage);

  return (
    <>
      <NavBar />
      <div className='page-container' style={{ backgroundColor: "#F7F9F9" }}>
        <h6 style={{ textAlign: "left", marginTop: "10px" }}>
          {" "}
          <Link to='/User/'>All models</Link> {" > "}
          {ModelName} {" > "}
          <span
            style={{
              cursor: "pointer",
              textDecoration: hover ? "underline" : "none",
            }}
            onClick={() => navigate("/User/Lots")}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {instanceName}
          </span>
          {" > "} Documents
        </h6>
        <DocStat lots={applySort(applyFilters(data))} />
        <div
          className='table-responsive'
          style={{
            padding: "10px",
            borderRadius: "5px",
            overflowX: "auto",
            lineHeight: "0.5",
          }}
        >
          <div style={{ height: "500px", overflowY: "auto" }}>


            <table className='table-striped' style={{ tableLayout: "fixed" }}>

              <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
                <tr style={{ height: "25px", verticalAlign: "middle" }}>
                  <th
                    style={{
                      backgroundColor: "#235073",
                      width: "150px",
                      color: "white",
                      verticalAlign: "middle",
                    }}                    
                  >
                    Actions
                  </th>

                  <th
                    style={{
                      backgroundColor: "#235073",
                      width: "390px",
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}                    
                  >
                    Nom du document
                  </th>
                  <th
                    style={{
                      backgroundColor: "#235073",
                      width: "10px",
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}                    
                  >
                    Id
                  </th>
                  <th
                    style={{
                      backgroundColor: "#235073",
                      width: "100px",
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Statut
                  </th>

                  <th
                      style={{
                        backgroundColor: "#235073",
                        width: "100px",
                        color: "white",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => toggleSort("CreationDate")}
                    >
                      Creation
                    </th>

                  {configData.displayFields?.LastUpdate && (
                    <th
                      style={{
                        backgroundColor: "#235073",
                        width: "100px",
                        color: "white",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => toggleSort("LastUpdate")}
                    >
                      MàJ
                    </th>
                  )}

                  {configData.displayFields?.isValid && (
                    <th
                      style={{
                        backgroundColor: "#235073",
                        width: "100px",
                        color: "white",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Validité
                    </th>
                  )}

                  {itemNames.map(
                    (itemName, index) =>
                      configData.displayFields?.[itemName] && (
                        <th
                          key={index}
                          style={{
                            backgroundColor: "#235073",
                            width: "120px",
                            color: "white",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          onClick={() => toggleSort(itemVars[index])}
                        >
                          {itemName}
                        </th>
                      )
                  )}
                </tr>
              </thead>

              <tbody style={{ overflowY: "auto" }}>
                
                <tr style={{ height: "25px", verticalAlign: "middle", position: "sticky", top: "25px", zIndex: 2 }}>

                  <td style={{ backgroundColor: "#a6c9e0" }}>
                    <input
                      type='checkbox'
                      checked={selectedDocuments.length === data.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedDocuments(data.map((item) => item.DocId));
                        } else {
                          setSelectedDocuments([]);
                        }
                      }}
                    />
                  </td>

                  <td
                    style={{
                      width: "390px",
                      backgroundColor: "#a6c9e0",
                      verticalAlign: "center",
                      textAlign: "center",
                    }}                    
                  >
                    <center>
                      <input
                        type='text'
                        className='form-control me-2 filter-input'
                        onChange={(e) =>
                          setFilter({ ...filter, FileName: e.target.value })
                        }
                        style={{
                          width: "390px",
                          fontSize: "14px",
                          height: "25px",
                        }}
                      />
                    </center>
                  </td>
                  <td style={{width: "10px",
                      backgroundColor: "#a6c9e0",
                      verticalAlign: "center",
                      textAlign: "center"
                  }}></td>
                  
                    <td style={{ backgroundColor: "#a6c9e0", width: "80px" }}>
                      <select
                        className='form-control'
                        onChange={(e) =>
                          setFilter({ ...filter, Status: e.target.value })
                        }
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          padding: "0px",
                          verticalAlign: "middle",
                        }}
                      >
                        <option value=''>Tous</option>
                        {uniqueStatuses.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </td>

                  {configData.displayFields?.LastUpdate && (
                    <td style={{ width: "100px", backgroundColor: "#a6c9e0" }}>
                      {sortField === "CreationDate" && sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }} />
                      ) : (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }} />
                      )}
                    </td>
                  )}

                  {configData.displayFields?.LastUpdate && (
                    <td style={{ width: "100px", backgroundColor: "#a6c9e0" }}>
                      {sortField === "LastUpdate" && sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }} />
                      ) : (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }} />
                      )}
                    </td>
                  )}


                  {configData.displayFields?.isValid && (
                    <td style={{ backgroundColor: "#a6c9e0", width: "70px" }}>
                      <select
                        className='form-control'
                        onChange={(e) =>
                          setFilter({ ...filter, isvalid: e.target.value })
                        }
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          padding: "0px",
                          verticalAlign: "middle",
                        }}
                      >
                        <option value=''>Tous</option>
                        <option value='true'>Vrai</option>
                        <option value='false'>Faux</option>
                      </select>
                    </td>
                  )}
                 

                  {itemNames.map(
                    (itemName, index) =>
                      configData.displayFields?.[itemName] && (
                        <td
                          key={index}
                          style={{
                            width: "120px",
                            backgroundColor: "#a6c9e0",
                            textAlign: "center",
                          }}
                        >
                          <select
                            className='form-control'
                            onChange={(e) =>
                              setFilter({
                                ...filter,
                                [itemVars[index]]: e.target.value,
                              })
                            }
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              height: "25px",
                              width: "100%",
                              padding: "0px",
                              verticalAlign: "middle",
                            }}
                          >
                            <option value=''>Tous</option>
                            {uniqueStatuses.map((status) => (
                              <option key={status} value={status}>
                                {status}
                              </option>
                            ))}
                          </select>
                        </td>
                      )
                  )}
                </tr>


                {applySort(applyFilters(data))
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((item) => (
                    <tr
                      key={item.DocId}
                      style={{ fontSize: "12px", verticalAlign: "middle" }}
                    >

                      <td
                        style={{
                          align: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.Attachmeents &&
                          item.Attachmeents.length > 0 &&
                          item.Attachmeents.filter(
                            (extension) =>
                              extension !== "" && extension !== null
                          ).map((extension, index) => (
                            <button
                              title='download attachement'
                              style={{
                                marginRight: "3px",
                                textAlign: "center",
                                height: "25px",
                                padding: "1px",
                              }}
                              key={index}
                              className='btn btn-primary'
                              onClick={() =>
                                handleDownload(item.DocId, extension)
                              }
                            >
                              {extension}
                            </button>
                          ))}
                        <button
                          title='upload attachement'
                          style={{
                            marginRight: "3px",
                            textAlign: "center",
                            height: "25px",
                            padding: "1px",
                          }}
                          className='btn btn-primary'
                          onClick={() => {
                            openModal();
                            setSelectedDocumentId(item.DocId);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{ marginRight: "5px" }}
                          />
                        </button>
                        <button
                          title='view document'
                          style={{
                            marginRight: "3px",
                            textAlign: "center",
                            height: "25px",
                            padding: "1px",
                          }}
                          className='btn btn-primary'
                          onClick={() =>
                            callGetModel(item.DocId, item.ModelId)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faFileAlt}
                            style={{ marginRight: "5px" }}
                          />
                        </button>
                        {item.Asana !== "" && (
                          <button
                            title='view task on Asana'
                            style={{
                              marginRight: "3px",
                              textAlign: "center",
                              height: "25px",
                              padding: "1px",
                            }}
                            className='btn btn-outline-secondary'
                            onClick={() =>
                              window.open(item.Asana.permalink, "_blank")
                            }
                          >
                            <FontAwesomeIcon icon={faLink} />
                          </button>
                        )}
                        <input
                          type='checkbox'
                          checked={selectedDocuments.includes(item.DocId)}
                          onChange={(e) =>
                            handleCheckboxChange(e, item.DocId)
                          } // Gérer les changements de checkbox
                        />
                      </td>

                      <td
                        style={{
                          align: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "390px"
                        }}
                        title={item.DocId}
                      >
                        {item.FileName}
                      </td>

                      <td
                        title='copy'
                        style={{
                          width:"10px",
                          align: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <CopyToClipboard
                          text={item.DocId}
                          onCopy={() => handleCopy(item.DocId)}
                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ cursor: "pointer" }}
                          />
                        </CopyToClipboard>
                      </td>

                      <td
                        style={{
                          align: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.Status}
                      </td>
                      
                        <td
                          style={{
                            align: "center",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {formatDate(item.CreationDate)}
                        </td>
                      

                      
                        <td
                          style={{
                            align: "center",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {formatDate(item.LastUpdate)}
                        </td>
                      

                      {configData.displayFields?.isValid && (
                        <td
                          style={{
                            align: "center",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <select
                            className='form-control'
                            value={
                              item.isvalid !== undefined
                                ? item.isvalid
                                  ? "true"
                                  : "false"
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, item.DocId, "isvalid")
                            }
                            style={{
                              width: "70px",
                              textAlign: "center",
                              height: "25px",
                              fontSize: "12px",
                              padding: "1px",
                              color: item.isvalid ? "#006400" : "#8B0000",
                              backgroundColor: item.isvalid
                                ? "#90EE90"
                                : "#FFA07A",
                            }}
                          >
                            <option value=''>Valide</option>
                            <option value='true'>Vrai</option>
                            <option value='false'>Faux</option>
                          </select>
                        </td>
                      )}
                                            
                      {itemVars.map(
                        (itemVar) =>
                          configData.displayFields?.[itemVar] && (
                            <td
                              style={{
                                align: "center",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {""}
                              {item[itemVar]}
                            </td>
                          )
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div></div>
        <div className='pagination d-flex justify-content-center'>

          <button
            className='btn btn-danger'
            onClick={() => navigate("/User/Lots")}
          >
            {" "}
            Retour{" "}
          </button>
          <button className='btn btn-primary' onClick={handleUpdateSelected}>
            Mettre à jour sélectionnés
          </button>{" "}
          <select
            id='itemsPerPage'
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
          {/* Bouton pour mettre à jour les documents sélectionnés */}
          {Array.from({ length: pageCount }, (_, i) => i + 1).map((number) => (
            <button
              key={number}
              className={`btn btn-outline-secondary ${currentPage === number ? "disabled" : ""
                }`}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </button>
          ))}
        </div>
      </div>

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel='Upload Document Modal'
        >
          <h2>Upload Document</h2>
          <input type='file' onChange={handleFileChange} />
          <button onClick={handleUpload}>Upload</button>
          <button onClick={closeModal}>Close</button>
        </Modal>
      )}
      { }
    </>
  );
};

export default DefaultDoc;

