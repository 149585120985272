/** @format */

import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { presignedURL } from "./presignedURL.js";
import { docUpdateComp, docUpdateValid } from "./docUpdate.js";
import "@fortawesome/fontawesome-free/css/all.min.css";

let changedDoc = true;

function DocViewer({ onUpdate }) {
  const instance = localStorage.getItem("instanceId");
  const model = localStorage.getItem("modelId");
  const idToken = Cookies.get("Token");
  const [documentData, setDocumentData] = useState([]);
  const [currentDocIndex, setCurrentDocIndex] = useState(0);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    const docs = JSON.parse(localStorage.getItem("docs")) || [];
    setDocumentData(docs);
    if (docs.length > 0) {
      setCurrentDocIndex(0);
    }
  }, []);

  const fetchPresignedUrl = async (docId) => {
    try {
      const url = await presignedURL(idToken, docId, model, instance);
      return url;
    } catch (error) {
      console.error(
        "Erreur lors de la récupération de l'URL présignée:",
        error
      );
      return null;
    }
  };

  useEffect(() => {
    const loadDocument = async () => {
      if (
        documentData.length > 0 &&
        currentDocIndex >= 0 &&
        currentDocIndex < documentData.length
      ) {
        const doc = documentData[currentDocIndex];
        setCurrentDocument(doc);
        setIsValid(doc.isvalid);
        const url = await fetchPresignedUrl(doc.DocId);
        if (changedDoc) {
          setPdfUrl(url); // Ne charger le PDF que lorsque le document change
          changedDoc = false;
        }
      }
    };

    loadDocument();
  }, [currentDocIndex, documentData]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${day} ${month} ${year}`;
  };

  const handleComplexityChange = async (complexity) => {
    try {
      console.log("update complexite");
      await docUpdateComp(idToken, instance, currentDocument.DocId, complexity);
      const updatedDocument = { ...currentDocument, Complexite: complexity };
      const updatedDocuments = [...documentData];
      updatedDocuments[currentDocIndex] = updatedDocument;

      setDocumentData(updatedDocuments);
      setCurrentDocument(updatedDocument);
      onUpdate(updatedDocument);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la complexité:", error);
    }
  };

  const handleValidityChange = async () => {
    const newIsValid = !isValid;
    try {
      console.log("update validité");
      await docUpdateValid(
        idToken,
        instance,
        currentDocument.DocId,
        newIsValid
      );
      const updatedDocument = { ...currentDocument, isvalid: newIsValid };
      const updatedDocuments = [...documentData];
      updatedDocuments[currentDocIndex] = updatedDocument;

      setDocumentData(updatedDocuments);
      setCurrentDocument(updatedDocument);
      setIsValid(newIsValid);

      onUpdate(updatedDocument);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la validité:", error);
    }
  };

  const handleNext = () => {
    if (currentDocIndex < documentData.length - 1) {
      changedDoc = true;
      setCurrentDocIndex(currentDocIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentDocIndex > 0) {
      changedDoc = true;
      setCurrentDocIndex(currentDocIndex - 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrev();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentDocIndex]);

  const getComplexityColor = (complexity, isActive) => {
    switch (complexity) {
      case "Complexe":
        return isActive ? "darkred" : "white";
      case "Moyen":
        return isActive ? "darkorange" : "white";
      case "Simple":
        return isActive ? "darkgreen" : "white";
      default:
        return "lightgray";
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => console.log("ID copié dans le presse-papiers!"),
      (err) =>
        console.error("Erreur lors de la copie dans le presse-papiers:", err)
    );
  };

  const closeTab = () => {
    window.close();
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div
        style={{ width: "15%", padding: "20px", borderRight: "1px solid #ccc" }}
      >
        <h3 style={{ marginBottom: "10px" }}>{currentDocument?.DocName}</h3>
        <p style={{ fontSize: "small", color: "#666" }}>
          Dernière mise à jour: {formatDate(currentDocument?.LastUpdate)}
        </p>
        <p style={{ marginBottom: "20px", fontWeight: "bold", fontSize:"10px" }}>
          <span style={{ marginLeft: "5px" }}>
            {currentDocument?.FileName}
          </span>
          <button
            onClick={() => copyToClipboard(currentDocument?.DocId)}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              color: "#007bff",
              fontSize: "20px",
              marginLeft: "5px",
            }}
          >
            <i className='fas fa-copy'></i>
          </button>
        </p>

        <div style={{ marginBottom: "20px" }}>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => handleComplexityChange("Complexe")}
              style={{
                backgroundColor: (currentDocument?.Complexite === "Complexe") ? "red" : "white",
                color: (currentDocument?.Complexite === "Complexe") ? "white" : "black",
                padding: "8px 12px",
                fontWeight: "bold",
                borderColor : "red",
                borderWidth : "3px",
                borderStyle : "solid",
                borderRadius: "5px",
                cursor: "pointer",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              Complexe
            </button>
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => handleComplexityChange("Moyen")}
              style={{
                backgroundColor: (currentDocument?.Complexite === "Moyen") ? "darkorange" : "white",
                color: (currentDocument?.Complexite === "Moyen") ? "white" : "black",
                padding: "8px 12px",
                fontWeight: "bold",
                borderColor : "darkorange",
                borderWidth : "3px",
                borderStyle : "solid",
                borderRadius: "5px",
                cursor: "pointer",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              Moyen
            </button>
          </div>
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => handleComplexityChange("Simple")}
              style={{
                backgroundColor: (currentDocument?.Complexite === "Simple") ? "darkgreen" : "white",
                color: (currentDocument?.Complexite === "Simple") ? "white" : "black",
                fontWeight: "bold",
                padding: "8px 12px",
                borderColor : "darkgreen",
                borderWidth : "3px",
                borderStyle : "solid",
                borderRadius: "5px",
                cursor: "pointer",
                width: "100%",
                marginBottom: "10px",
              }}>
              Simple
            </button>
          </div>

          <div style={{ marginTop: "20px" }}>
            <hr style={{ margin: "20px 0" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >  
            {isValid ? (
              <button
                onClick={handleValidityChange}
                style={{
                  padding: "8px 12px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  borderWidth : "3px",
                  borderStyle : "solid",
                  borderColor: "red",
                  backgroundColor: isValid ? "red" : "white",
                  color: isValid ? "white" : "red",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Invalider
              </button>
            ) : (
              <button
              style={{
                padding: "8px 12px",
                cursor: "pointer",
                fontWeight: "bold",
                borderRadius: "5px",
                borderWidth : "3px",
                borderStyle : "solid",
                borderColor: "red",
                backgroundColor: isValid ? "red" : "white",
                color: isValid ? "white" : "red",
                textAlign: "center",
                width: "100%",
              }}
            >
              invalide
            </button>
            )}
            </div>
          </div>
        </div>
        <hr style={{ margin: "20px 0" }} />
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            onClick={handlePrev}
            disabled={currentDocIndex === 0}
            style={{
              padding: "8px 12px",
              cursor: currentDocIndex === 0 ? "not-allowed" : "pointer",
              borderRadius: "5px",
              border: "1px solid #ccc",
              backgroundColor: currentDocIndex === 0 ? "#e0e0e0" : "#fff",
              width: "48%",
            }}
          >
            Précédent
          </button>
          <button
            onClick={handleNext}
            disabled={currentDocIndex === documentData.length - 1}
            style={{
              padding: "8px 12px",
              cursor:
                currentDocIndex === documentData.length - 1
                  ? "not-allowed"
                  : "pointer",
              borderRadius: "5px",
              border: "1px solid #ccc",
              backgroundColor:
                currentDocIndex === documentData.length - 1
                  ? "#e0e0e0"
                  : "#fff",
              width: "48%",
            }}
          >
            Suivant
          </button>
        </div>
        <hr style={{ margin: "20px 0" }} />
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}>
          <button
            onClick={closeTab}
            style={{
              padding: "8px 12px",
              cursor: "pointer",
              borderRadius: "5px",
              border: "1px solid #ccc",
              backgroundColor: "#e0e0e0",
              width: "100%",
            }}
          >
            Fermer
          </button>
        </div>
      </div>

      <div style={{ width: "85%", padding: "20px" }}>
        {pdfUrl ? (
          <iframe
            src={pdfUrl}
            title='Document PDF'
            style={{
              width: "100%",
              height: "calc(100vh - 40px)",
              border: "none",
            }}
          />
        ) : (
          <p>Aucun document sélectionné.</p>
        )}
      </div>
    </div>
  );
}

export default DocViewer;
