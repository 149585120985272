import {Routes, Route} from 'react-router-dom'
import Login from '../Component/Login/Login'
import DisplayDocument from '../Component/PDFWebsite/PDFWebsit'
export default function RouterDefault() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
    </Routes>
    )
}
