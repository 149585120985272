/** @format */
import apiConfig from "../config/_config"; // api.js
import process from "process";
const API_BASE_URL = process.env.REACT_APP_API_URL;
export async function getModel(token) {
  const apiUrl = `${API_BASE_URL}/model/list `;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.message.list;
  } catch (error) {
    throw new Error(error);
  }
}
