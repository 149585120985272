import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DisplayDocument = () => {
    const location = useLocation();

    const getQueryParams = () => {
        const params = new URLSearchParams(location.search);
        return {
            baseurl: "https://api-imfdev.myactivhub.net/webhooks/myactivhub/displaydoc/",
            instanceid: params.get('InstanceId'),
            documentid: params.get('DocumentId'),
            modelid: params.get('ModelId'),
            clientid: params.get('ClientId'),
        };
    };

    useEffect(() => {
        const { baseurl, instanceid, documentid, modelid, clientid } = getQueryParams();
        console.log('baseurl=', baseurl);
        console.log('instanceid=', instanceid);
        console.log('documentid=', documentid);
        console.log('modelid=', modelid);
        console.log('clientid=', clientid);

        const viewDocument = () => {
            const url = `${baseurl}?InstanceId=${instanceid}&DocumentId=${documentid}&ModelId=${modelid}&ClientId=${clientid}`;
            console.log('fetching url:', url);

            fetch(url, {
                method: 'GET',
                headers: {
                    'x-api-key': "yD5RK2tDaV8hpTUieuWot9U22qdl9Pbp2U0vmezk"
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.text();
            })
            .then(text => {
                console.log("Response text:", text);
                if (text) {
                    window.open(text, '_blank'); // Open the URL in a new tab
                } else {
                    throw new Error('URL not found in response');
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
        };

        viewDocument();
    }, [location.search]);

    return (
        <div>
            <script src="https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js"></script>
            <title>Display Image</title>
        </div>
    );
};

export default DisplayDocument;
